import { useEffect, useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import Flex from '../components/flex';
import { UserAddOutlined, HeartOutlined, CheckCircleFilled, RightOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/react-flicking/dist/flicking-inline.css';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

const plugins1 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins2 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins3 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins4 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins5 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins6 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];
const plugins7 = [new Pagination({ type: 'bullet', selector: '.flicking-pagination' })];

const TopTitle = styled.section<{ color?: string }>`
padding: 10px 0;
background-color: ${({ theme, color }) => color ? color : theme.colors.white.base};
box-sizing: border-box;
text-align: center;
border-bottom: 1px lightgray solid;
`;

const MiddleTopText = styled.div`
margin: 40px 20px 0 20px;
font-weight: 700;
border-bottom: 2px solid lightgray;
`;

const MiddleContainer = styled.div`
font-size: 12px;
padding: 16px;
border: 1px solid #f2f2f2;
border-radius: 4px;
background-color: #ffffff;
margin-bottom: 20px;
`;

const MiddleTopContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
justify-content: space-evenly;
margin-bottom: 30px;
`;

const MiddleBottomContainer = styled.div`
flex: 1;
padding: 10px 0;
`;

const MiddleTableLeftText = styled.td`
font-size: 12px;
width: 90px;
`;

const MiddleTableRightText = styled.td`
font-size: 12px;
font-weight: bold;
`;

const MiddleRightTopText = styled.div`
font-size: 16px;
text-align: center;
`;

const MiddleRightBottomText = styled.div`
font-size: 20px;
font-weight: bold;
text-align: center;
margin-top: 10px;
color: #0095ff;
`;

const BottomContainer = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 20px;
`;

const BottomTable = styled.table`
display: flex;
flex-direction: column;
flex: 1;
border-collapse: collapse;
margin-bottom: 16px;
`;

const BottomTableTr = styled.tr`
position: relative;
display: flex;
flex-direction: column;
font-size: 12px;
padding: 16px;
border: 1px solid #f2f2f2;
border-radius: 4px;
background-color: #ffffff;
margin-bottom: 16px;
`;

const BottomTableTop = styled.tr`
`;

const BottomTableProcessBar = styled.div`
height: 14px;
display: flex;
flex-direction: row;
border: 1px solid lightgray;
border-radius: 5px;
margin: 0 40px;
overflow: hidden;
`;

const fillWidth = (length: number) => keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${length}%;
    }
`;

const FillBar = styled.div<{ length: number }>`
animation: ${({ length }) => fillWidth(length)} 3s;
width: ${({length}) => length}%;
background-color: #0095ff;
`;

const BottomTableProcessCnt = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const BottomTableProcessCntLeft = styled.div<{ length: number }>`
width: ${({length}) => length}%;
text-align: right;
font-size: 12px;
color: #8A91A2;
font-weight: bold;
`;

const BottomTableBottom = styled.tr`
padding: 0 40px;
`;

const BottomTableLeftText = styled.td`
font-size: 11px;
width: 80px;
`;

const BottomTableRightText = styled.td`
font-size: 11px;
font-weight: bold;
`;

const RecommendContainer = styled.div`
display: flex;
flex-direction: column;
`;

const Link = styled.a`
`;

const ASButton = styled.button`
position: absolute;
top: 16px;
right: 16px;
font-size: 12px;
background-color: #f9f9f9;
border: solid 1px #9A99A9;
padding: 4px 8px;
border-radius: 4px;
cursor: pointer;
`;

const PopUpBackground = styled.div`
background-color: rgba(0,0,0,.4);
justify-content: center;
align-items: center;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
padding: 30px 0;
z-index: 1;
touch-action: none;
`;

const PopUpBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 80%;
background-color: white;
border-radius: 25px;
padding: 0 25px;
box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
`;

const PopUpContent = styled.div`
flex: 1;
`;

const PopUpHeader = styled.div`
margin-top: 35px;
text-align: center;
font-size: 18px;
font-weight: bold;
`;

const PopUpBody = styled.div`
flex: 1;
margin-top: 15px;
text-align: center;
`;

const PopUpCloseButton = styled.div`
display: flex;
color: gray;
justify-content: center;
align-items: center;
height: 50px;
border-radius: 0 0 30px 30px;
font-size: 13px;
:hover {
    cursor: pointer;
}
`;

const InstaLink = styled.a`
text-decoration: none;
color: #0095ff;
`;

const Bar = styled.div`
position: relative;
height: 8px;
background-color: #e5e5e5;
border-radius: 4px;
overflow: hidden;
`;

const BarInner = styled.div<{ ratio: number }>`
animation: ${({ ratio }) => fillWidth(ratio * 100)} 3s;
position: absolute;
top: 0;
left: 0;
bottom: 0;
width: ${({ ratio }) => `${ratio * 100}%`};
background-color: #0095ff;
border-radius: 4px;
transition: width 0.5s ease-in-out;
`;

const CardTitle = styled.div`
font-size: 16px;
font-weight: 800;
margin-bottom: 8px;
`;

const Wrapper = styled.div`
display: flex;
justify-content: center;
`;

const Container = styled.div<{ mobile: boolean }>`
width: -moz-available;
width: -webkit-fill-available;
width: fill-available;
min-height: 100vh;
box-shadow: 0 4px 4px rgb(0 23 80 / 1%), 0 1px 6px rgb(0 23 80 / 2%),
    0 8px 8px rgb(0 23 80 / 1%), 0 16px 16px rgb(0 23 80 / 1%),
    8px 32px 32px rgb(0 23 80 / 2%), 8px 64px 64px rgb(0 23 80 / 2%);
background-color: #f8f8f8;
display: flex;
flex-direction: column;
${({ mobile }) => !mobile && `max-width: 375px`};
padding-bottom: 80px;
`;

const FlickerWrapper = styled.div`
.flicking-pagination {
    bottom: 0px;
}
.flicking-pagination-bullet-active {
    background-color: #FE9C49;
}
`;

export enum OrderProcessStatusType {
    DONE = 'done', // 작업이 다 끝남
    // NOTE: order task에 status 이관
    // QUEUED = 'queued', // 스케줄이 만들어져서 작업 큐잉이 됨
    // LOCKED = 'locked', // 스케줄러가 긁어갔지만 작업 큐잉은 안됨
    WAIT = 'wait', // 스케줄러가 긁어가야 하는 상태
    CANCEL = 'cancel',
};

export enum OrderTaskType {
    LIKE = 'like',
    LIKE_REELS = 'like_reels',
    FOLLOW = 'follow',
};

export enum OrderTaskStatusType {
    // 작업이 다 끝난 상태
    FINISHED = 'finished',
    // 문제로 인해서 작업이 불가능한 상태
    IMPOSSIBLE = 'impossible',
    // 스케줄러가 긁어간 상태 (중복 스케줄링 방지)
    LOCKED = 'locked',
    // 스케줄러가 긁어가길 기다리는 상태
    NOT_FINISHED = 'not_finished',
    // 외부 벤더의 처리를 기다리는 상태
    WAIT_EXTERNAL_VENDOR = 'wait_external_vendor',
};

export interface GuestOrderTaskDto {
    // '상품 이름',
    productName: string;
    // '진행한 갯수',
    progressCnt: number;
    // '진행할 갯수',
    totalCnt: number;
    // '작업큐에 있는 갯수',
    remainScheduleCnt: number;
    // '작업 종류',
    taskType: OrderTaskType;
    // '대상 IG id',
    targetId: string;
    //   '대상 IG url',
    targetUrl: string;
    //   '작업 진행 상황',
    taskStatus: OrderTaskStatusType;
    // 'as 받은 수량',
    asCount: number;
    // 'as 가능 여부'
    canAs: boolean;
    // 'orderTaskId'
    id: string;
};

export interface GuestOrderProgressDto {
    //   '주문 번호',
    orderNo: number;
    //   '주문 처리 상태',
    orderProcessStatus: OrderProcessStatusType;
    //   '주문자 이름',
    orderName: string;
    //   '주문자 이름',
    orderDate: string;
    //   '작업 리스트',
    orderTasks: GuestOrderTaskDto[];
    //   '프로모션 체험 여부'
    proposePromotionType?: string;
};

export interface FlashNotificationDto {
    // id
    id: string;
    // 작성일자
    createdAt: string;
    // 내용
    content: string;
};

function getOrderTaskType(type: OrderTaskType | undefined) {
    if (type === OrderTaskType.LIKE || type === OrderTaskType.LIKE_REELS) {
        return '좋아요';
    }
    if (type === OrderTaskType.FOLLOW) {
        return '팔로워';
    }
    else {
        return '.....';
    }
};

const OrderMobile = () => {
    const url = useLocation();
    const { orderId } = useParams();
    const token = new URLSearchParams(url.search).get('token');

    const CallApi = async (id: string, token: string) => {
        try {
            const rs = await axios.get(`https://api.staging.among.world/insta-leader/guests/order-progress/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (rs) {
                return rs.data;
            }
        } catch (error) {
            return false;
        }
    };

    const getNotification = async () => {
        try {
            const rs = await axios.get('https://api.staging.among.world/insta-leader/guests/notifications', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (rs) {
                return rs.data;
            }
        } catch (error) {
            return false;
        }
    };

    const ASConfirm = async (orderId: string, orderTaskId: string, token: string) => {
        if (window.confirm('A/S를 요청합니다.')) {
            try {
                const rs = await axios.post(`https://api.staging.among.world/insta-leader/guests/order-progress/${orderId}/order-tasks/${orderTaskId}/as`,
                {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (rs && rs.status === 201) {
                    alert('요청 완료');
                }
            } catch (error) {
                alert('요청 실패 고객센터에 문의하세요');
            }
        } else {
            return;
        }
    };

    const [ orderInfo, setOrderInfo ] = useState <GuestOrderProgressDto | undefined> (undefined);
    const [ urlError, setUrlError ] = useState <'orderId' | 'token' | undefined> (undefined);
    const [ isPopUp, setIsPopUp ] = useState<boolean>(false);
    const [ notificationList, setNotificationList ] = useState<Array<FlashNotificationDto>>();

    useEffect(() => {
        (async () => {
            if (orderId === undefined) {
                // TODO:
                setUrlError('orderId');
                return;
            }
            if (token === null) {
                // TODO:
                setUrlError('token');
                return;
            }
            const rs = await CallApi(orderId, token);
            if (rs) {
                setOrderInfo(rs);
            }
        })();
    }, [orderId, token]);

    useEffect(() => {
        (async () => {
            const rs = await getNotification();
            if (rs) {
                setNotificationList(rs);
            }
        })();
    }, []);

    const orderProcessStatus = useMemo(() => {
        if (orderInfo?.proposePromotionType) {
            setIsPopUp(true);
        }
        switch (orderInfo?.orderProcessStatus) {
            case OrderProcessStatusType.WAIT:
                if ((orderInfo?.orderTasks ?? []).some(orderTask => orderTask?.progressCnt > 0)) {
                    return '작업이 진행중입니다.';
                }
                return '작업이 대기중입니다.';
            case OrderProcessStatusType.DONE:
                return '작업이 완료되었습니다.';
            case OrderProcessStatusType.CANCEL:
                return '작업이 취소되었습니다.'
            default:
                return '.....';
        }
    }, [ orderInfo ]);

    return (
        <>
        { urlError ? (
            <main>
                <div style={{ height: 200, textAlign: 'center', paddingTop: 50 }}>주문을 조회할 수 없습니다.<br/>고객센터로 문의해주세요</div>
            </main>
        ) : (
        <Wrapper>
            <Container mobile={isMobileOnly}>
                <main style={{ padding: '0 16px 70px 16px' }}>
                    { isPopUp && (
                        <PopUpBackground>
                            <PopUpBox>
                                <PopUpContent>
                                    <PopUpHeader>
                                        깜짝 선물이 도착했어요!
                                    </PopUpHeader>
                                    <PopUpBody>
                                        <div style={{ backgroundColor: '#ffeddc', padding: '20px 0 10px 0', borderRadius: 5, marginBottom: 30 }}>
                                            <img
                                                src='/images/products/promotion_popup.png'
                                                width='150px'
                                                style={{ margin: '10px 0' }}
                                                alt='popup_img'
                                            ></img>
                                        </div>
                                        <div style={{ fontSize: 14, marginBottom: 20, marginTop: 10 }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: 5 }}>"인스타 한국인 좋아요 50회 구독 (5일)"</div>
                                            <div style={{ fontSize: 12 }}>
                                                {orderInfo?.orderDate && moment(orderInfo?.orderDate).format('YYYY년 MM월 DD일')}
                                                ~
                                                {orderInfo?.orderDate && moment(orderInfo?.orderDate).add(4, 'days').format('YYYY년 MM월 DD일')}
                                            </div>
                                        </div>
                                    </PopUpBody>
                                </PopUpContent>
                                <div style={{ height: 1, backgroundColor: '#dddddd' }} ></div>
                                <PopUpCloseButton
                                    onClick={() => setIsPopUp(false)}
                                >
                                    닫기
                                </PopUpCloseButton>
                            </PopUpBox>
                        </PopUpBackground>
                    )}
                    <div style={{ fontSize: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px 0' }}>
                        <div style={{ borderBottom: '1px solid', marginBottom: 16 }}>
                            <NavLink to='/membership' style={{ textDecoration: 'none', color: 'black' }}>
                                {'💳 멤버십 등급제 시작 안내 >'}
                            </NavLink>
                        </div>
                        <div style={{ borderBottom: '1px solid' }}>
                            <NavLink to='/review-event' style={{ textDecoration: 'none', color: 'black' }}>
                                {`⭐ 리뷰적립금 이벤트 개편 안내 >`}
                            </NavLink>
                        </div>
                    </div>
                    <NavLink to='/review-event' style={{ textDecoration: 'none', color: 'black' }}>
                        <div style={{
                            padding: '16px',
                            border: '1px solid #f2f2f2',
                            borderRadius: '4px',
                            backgroundColor: '#ffffff',
                        }}>
                            <Flex justifyContent='space-between'>
                                <div style={{ fontSize: 14 }}>
                                    리뷰 쓰고<br />
                                    <span style={{ color: '#E1306C', fontWeight: 'bold' }}>1,000</span> 포인트 받기!
                                </div>
                                <RightOutlined style={{ color: '#8A91A2', fontSize: 18 }} />
                            </Flex>
                        </div>
                    </NavLink>
                    <div style={{ marginBottom: 10, marginTop: 20 }}>
                        { notificationList && notificationList.length > 0 && notificationList.map((d) => {
                            return (
                                <div style={{ textAlign: 'center', marginBottom: 10, fontWeight: 'bold' }}>
                                    <div style={{ fontSize: 13 }}>
                                        {`🚨 긴급공지 ${moment(d.createdAt).format('MM/DD (HH:mm)')} 🚨`}
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#ffe9e9',
                                            borderRadius: '20px',
                                            border: '2px solid #ff9999',
                                            padding: '10px 20px',
                                            marginTop: '5px',
                                            fontSize: 14,
                                        }}
                                    >
                                        {d.content}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <h1 style={{ fontSize: 20, marginBottom: 40, marginTop: 50 }}>
                        {orderProcessStatus}
                    </h1>
                    { orderInfo?.proposePromotionType && (
                        <div style={{ margin: '10px 0', textAlign: 'center', backgroundColor: '#ffeddc', padding: '20px 0', borderRadius: 5 }}>
                            <div style={{ fontSize: 16, marginBottom: 10 }}>
                                <div style={{ fontWeight: 'bold' }}>"인스타 한국인 좋아요 50회 구독 (5일)"</div>
                                <div>깜짝 선물!</div>
                            </div>
                            <img
                                src='/images/products/promotion_popup.png'
                                width='100px'
                                style={{ margin: '10px 0' }}
                                alt='popup_img'
                            ></img>
                            <div style={{ fontSize: 14, marginBottom: 10, lineHeight: '20px' }}>
                                {orderInfo?.orderDate && moment(orderInfo?.orderDate).format('YYYY년 MM월 DD일')}
                                ~
                                {orderInfo?.orderDate && moment(orderInfo?.orderDate).add(4, 'days').format('YYYY년 MM월 DD일')}
                                <br/>
                                <strong>5일 동안 매일 올라오는 최초 1개의 게시글</strong>에<br/><strong>한국인 좋아요 50회</strong>가 제공됩니다.</div>
                            <div style={{ fontSize: 12 }}>체험을 원하시지 않는 경우 인스타대장 고객센터로 문의해주세요</div>
                        </div>
                    )}
                    <h2 style={{ fontSize: 16 }}>주문 정보</h2>
                    <MiddleContainer>
                        <MiddleTopContainer>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-evenly' }}>
                                <MiddleRightTopText>주문 완료 / 총 주문</MiddleRightTopText>
                                <MiddleRightBottomText>
                                    {`${orderInfo?.orderTasks.filter(d => d.taskStatus === OrderTaskStatusType.FINISHED).length ?? '-'} / ${orderInfo?.orderTasks.length ?? '-'}`}
                                </MiddleRightBottomText>
                            </div>
                        </MiddleTopContainer>
                        <div>
                            <Flex style={{ width: '100%', marginBottom: 12, fontWeight: 'bold' }} justifyContent='space-between'>
                                <div style={{
                                    textAlign: 'left',
                                    fontSize: 14,
                                }}>성함</div>
                                <div style={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    color: '#8A91A2',
                                }}>{orderInfo?.orderName}</div>
                            </Flex>
                            <Flex style={{ width: '100%', marginBottom: 12, fontWeight: 'bold' }} justifyContent='space-between'>
                                <div style={{
                                    textAlign: 'left',
                                    fontSize: 14,
                                }}>주문번호</div>
                                <div style={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    color: '#8A91A2',
                                }}>{orderInfo?.orderNo}</div>
                            </Flex>
                            <Flex style={{ width: '100%', fontWeight: 'bold' }} justifyContent='space-between'>
                                <div style={{
                                    textAlign: 'left',
                                    fontSize: 14,
                                }}>주문시간</div>
                                <div style={{
                                    textAlign: 'right',
                                    fontSize:  14,
                                    color: '#8A91A2',
                                }}>{orderInfo?.orderDate && moment(orderInfo?.orderDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </Flex>
                        </div>
                    </MiddleContainer>
                    <BottomContainer>
                        <h2 style={{ fontSize: 16 }}>상세 정보</h2>
                        <BottomTable>
                            <tbody>
                                { orderInfo?.orderTasks.map((d, i, arr) =>
                                    <BottomTableTr>
                                        <ASButton
                                            onClick={() => {
                                                if (d.canAs && orderId && token && d.id) {
                                                    ASConfirm(orderId, d.id, token);
                                                } else {
                                                    window.alert('A/S 대상이 아닙니다.');
                                                }
                                            }}
                                        >A/S</ASButton>
                                        <CardTitle>
                                            {arr.length === 1 ? d.productName : `${i + 1}. ${d.productName}`}
                                            { getOrderTaskType(d.taskType) === '좋아요' && <HeartOutlined style={{ color: '#E1306C', fontSize: 14, marginLeft: 5, marginTop: 2 }} /> }
                                            { getOrderTaskType(d.taskType) === '팔로워' && <UserAddOutlined style={{ color: '#0095f6', fontSize: 14, marginLeft: 5, marginTop: 2 }} /> }
                                        </CardTitle>
                                        { getOrderTaskType(d.taskType) === '팔로워' &&
                                            <a
                                                href={`https://www.instagram.com/${d.targetId}`}
                                                target='_blank'
                                                rel='noreferrer noopener'
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Flex
                                                    gap={5}
                                                    justifyContent='flex-start'
                                                    style={{ color: '#8A91A2', fontSize: 14 }}
                                                >
                                                    <img
                                                        src='/images/icons/link.png'
                                                        width={14}
                                                        height={14}
                                                        style={{ display: 'block' }}
                                                    />
                                                    <div>{d.targetId}</div>
                                                </Flex>
                                            </a>
                                        }
                                        { getOrderTaskType(d.taskType) === '좋아요' &&
                                            <a
                                                href={`https://www.instagram.com/p/${d.targetUrl}`}
                                                target='_blank'
                                                rel='noreferrer noopener'
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Flex
                                                    gap={5}
                                                    justifyContent='flex-start'
                                                    style={{ color: '#8A91A2', fontSize: 14 }}
                                                >
                                                    <img
                                                        src='/images/icons/link.png'
                                                        width={14}
                                                        height={14}
                                                        style={{ display: 'block' }}
                                                    />
                                                    <div style={{
                                                        flex: '1 1 auto',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        marginRight: '20%',
                                                    }}>
                                                        {`www.instagram.com/p/${d.targetUrl}`}
                                                    </div>
                                                </Flex>
                                            </a>
                                        }
                                        <div style={{ marginTop: 30 }}>
                                            <div>
                                                <Flex
                                                    gap={20}
                                                    justifyContent='space-between'
                                                    alignItems='flex-end'
                                                >
                                                    <div style={{ flex: 1, marginBottom: 6 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                                            { d.progressCnt &&
                                                                <BottomTableProcessCntLeft
                                                                    length={ d.progressCnt > d.totalCnt ? 100 : d.progressCnt / d.totalCnt * 100 }
                                                                >
                                                                    {Math.floor(Math.min(d.progressCnt / d.totalCnt, 1) * 100)}%
                                                                </BottomTableProcessCntLeft>
                                                            }
                                                            <div style={{ flex: 1 }}></div>
                                                        </div>
                                                        <Bar>
                                                            <BarInner ratio={ d.progressCnt / d.totalCnt } />
                                                        </Bar>
                                                    </div>
                                                    <BottomTableProcessCnt>
                                                        { Math.min(d.progressCnt, d.totalCnt) === d.totalCnt
                                                            ? (
                                                                <CheckCircleFilled style={{ fontSize: 20, color: '#0095ff' }} />
                                                            )
                                                            : (
                                                                <div style={{ textAlign: 'right', fontSize: 14, color: '#8A91A2' }}>
                                                                    {Math.min(d.progressCnt, d.totalCnt)}/{d.totalCnt}
                                                                </div>
                                                            )
                                                        }
                                                    </BottomTableProcessCnt>
                                                </Flex>
                                                { Math.max(d.progressCnt - d.totalCnt, 0) > 0 && !d.productName.includes('외국인') &&
                                                    <div style={{ flex: 1 }}>
                                                        <Bar style={{ background: 'transparent' }}>
                                                            <BarInner
                                                                ratio={ (d.progressCnt - d.totalCnt) / d.totalCnt }
                                                                style={{ background: '#FE9C49' }}
                                                            />
                                                        </Bar>
                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                                            { d.progressCnt &&
                                                                <BottomTableProcessCntLeft
                                                                    length={ (d.progressCnt - d.totalCnt) / d.totalCnt * 100 }
                                                                    style={{ color: '#FE9C49' }}
                                                                >
                                                                    +{ Math.max(d.progressCnt - d.totalCnt, 0) }
                                                                </BottomTableProcessCntLeft>
                                                            }
                                                            <div style={{ flex: 1, position: 'relative' }}>
                                                                <img
                                                                    style={{
                                                                        position: 'absolute',
                                                                        left: 20,
                                                                        top: -10,
                                                                    }}
                                                                    src='/images/icons/bonus.png'
                                                                    width={30}
                                                                    height={30}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </BottomTableTr>
                                )}
                            </tbody>
                        </BottomTable>
                    </BottomContainer>
                    <div style={{ textAlign: 'center' }}>
                        <h1 style={{ fontSize: 20, marginBottom: 30 }}>상품 둘러보기</h1>
                    </div>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>패키지</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins1}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000025'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/packages/silsok.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000026'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/packages/mongdang.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>자동 좋아요</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins4}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000031'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요50_10.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000032'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요50_30.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000033'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요50_100.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000034'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요100_10.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000035'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요100_30.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000036'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/auto_likes/자동좋아요100_100.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>좋아요 구독</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins5}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000016'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/30일좋아요50.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000017'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/30일좋아요100.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000029'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/데일리좋아요250.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000021'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/데일리좋아요500.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000023'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/데일리좋아요50_4.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000024'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/데일리좋아요100_4.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000030'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/subscription_likes/데일리좋아요250_4.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>한국인 팔로워</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins2}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000001'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_followers/한국인팔로워50.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000000'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_followers/한국인팔로워250.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000002'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_followers/한국인팔로워500.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000003'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_followers/한국인팔로워1000.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>한국인 좋아요</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins3}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000008'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_likes/한국인좋아요50.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000009'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_likes/한국인좋아요100.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000010'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_likes/한국인좋아요500.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000011'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/korean_likes/한국인좋아요1000.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>외국인 팔로워</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins6}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000004'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_followers/외국인팔로워100.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000005'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_followers/외국인팔로워250.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000006'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_followers/외국인팔로워500.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000007'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_followers/외국인팔로워1000.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                    <h2 style={{ fontSize: 16, marginBottom: 10 }}>외국인 좋아요</h2>
                    <FlickerWrapper>
                        <Flicking
                            viewportTag='div'
                            cameraTag='div'
                            cameraClass=''
                            renderOnSameKey={false}
                            preventClickOnDrag={true}
                            align='center'
                            moveType='snap'
                            panelsPerView={2}
                            horizontal={true}
                            circular={false}
                            // bound={true}
                            // bounce={0.1}
                            plugins={plugins7}
                        >
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000012'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_likes/외국인좋아요100.jpg'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000019'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_likes/실제계정외국인500.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <div style={{ margin: '0 10px 30px 0' }}>
                                <Link
                                    href='https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000020'
                                    target='_blank'
                                >
                                    <img
                                        src='/images/products/foreigner_likes/실제계정외국인좋아요1000.png'
                                        width='100%'
                                    />
                                </Link>
                            </div>
                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </FlickerWrapper>
                </main>
            </Container>
        </Wrapper>
        )}
        </>
    );
};

export default OrderMobile;