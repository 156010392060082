import HomeDesktop from './pages/HomeDesktop';
import { BrowserView, MobileView } from 'react-device-detect';
import HomeMobile from './pages/HomeMobile';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OrderMobile from './pages/OrderMobile';
import Membership from './pages/membership';
import ReviewEvent from './pages/reviewEvent';

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={
                        <>
                        <BrowserView>
                            <HomeDesktop />
                        </BrowserView>
                        <MobileView>
                            <HomeMobile />
                        </MobileView>
                        </>
                    }></Route>
                    <Route path='/membership' element={<Membership />} />
                    <Route path='/review-event' element={<ReviewEvent />} />
                    <Route path='/order/:orderId' element={<OrderMobile />} />
                    <Route path='*' element={
                        <>
                        <div style={{ height: 200, textAlign: 'center', paddingTop: 50 }}>잘못된 링크입니다.<br/>고객센터로 문의해주세요</div>
                        </>
                    }></Route>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;